/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
    .progress-div-success {
        width: 80%;
    }
    .progress-div-success-status {
        width: 100%;
    }
    .progress-div-success-status> :nth-child(3) {
        margin-right: 55px;
    }
    .progress-div-success-status> :nth-child(1) {
        margin-left: 35px;
    }
    .success-img-div {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
    .success-img-div>img {
        width: 50% !important;
        margin-top: 2.1%; 
    }
    .success-text-heading {
        padding: 30px 0px;
        font-size: 40px !important;
    }
    .success-btns {
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 90px;
        padding: 0% 6.8%;
    }
    .success-btns>:nth-child(1) {
        width: 100% !important;
        margin-right: 0px !important;
        font-size: 24px !important;
    }
    .success-btns>:nth-child(2) {
        width: 100% !important;
        margin-right: 0px;
        font-size: 24px !important;
    }
    .success-coin-data-div {
        display: flex;
        justify-content: center;
        margin-bottom: 22px;
        flex-direction: column;
        align-items: center;
        padding: 15% 6.8% 0%;
    }
    .toicon_desktop{
        display: none;
    }
    .toicon_mobile {
        display: block;
        height: 60px;
    }
    .success-coin-data-div> :nth-child(1) {
        width: 100% !important;
    }
    .success-coin-data-div> :nth-child(1)> :nth-child(2)> span {
        color: white;
        font-weight: bold;
        font-size: 16px !important;
    }
    .success-coin-data-div> :nth-child(1)> :nth-child(2)> img {
        width: 24px !important;
        margin-right: 7px !important;
    }
    .success-you-send-amount {
        padding-left: 45px;
    }
    .success-you-send-amount> :nth-child(1) {
        font-size: 16px !important;
    }    
    .success-you-send-amount> :nth-child(2) {
        font-size: 20px !important;
        font-weight: bold;
    }
    .toicon {
        padding: 30px 0px;
    }
    .success-coin-data-div> :nth-child(3) {
        padding: 0% 1.8%;
        width: 100%;
    }
    .success-coin-data-div> :nth-child(3)> :nth-child(2)> span {
        font-size: 16px !important;
    }
    .success-you-get-amount> :nth-child(1) {
        font-size: 16px !important;
    }
    .success-you-get-amount> :nth-child(2) {
        font-size: 20px !important;
        font-weight: bold;
    }
    .success-coin-data-div> :nth-child(1)> :nth-child(2) {
        justify-content: end;
        display: flex;
        align-items: center;
        width: 22%;
        padding-right: 47px;
    }
    .success-coin-data-div> :nth-child(3)> :nth-child(2)> img {
        width: 24px !important;
        margin-right: 7px !important;
    }
    .success-coin-data-div> :nth-child(3) {
        padding: 0% 8.8% !important;
        width: 100% !important;
    }
    .success-completion {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        padding-left: 55px;
    }
    .adressRow_desktop{
        display: none;
    }
    .adressRow_mobile {
        display: block !important;
        width: 100%;
    }
    .adress_deposit {
        text-align: center;
        margin: 0px auto;
        padding-top: 60px;
    }
    .deposit-address-width {
        width: 631px !important;
    }
    span.react-draggable {
        margin-left: 0px !important;
    }
    .adress_recipient {
        margin: 0px auto;
        text-align: center;
        padding-top: 58px;
    }
    .recipient-address-width {
        width: 631px !important;
    }
    .left-round-div.TX_Hash {
        text-align: center;
        margin: 0px auto;
        padding-top: 50px;
    }
    .txhash-width {
        width: 556px !important;
    }
    span.react-draggable {
        margin-left: -41px;
        font-size: 16px;
        color: #A3A09E;
    }
    .exchange_note {
        padding-left: 5%;
    }
    .tracker_div {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .ordertracker_rounded {
        width: 631px;
        margin: 0px auto;
    }
    .tracker_id {
        margin: 0px auto !important;
        text-align: center;
        padding-top: 140px;
    }
    .ordertracker_rounded {
        width: 660px;
        margin: 0px auto;
    }
    .partner_exchange {
        text-align: center;
        padding-top: 55px;
    }
    .local_exchange_img {
        margin: 10px auto;
    }
    .floating-box-progress {
        width: 100% !important;
        margin: 0px auto !important;
        margin-right: 0px !important;
        text-align: center !important;
        padding-top: 20px !important;
    }
    .floating_rounded {
        width: 665px;
        margin: 0px auto;
    }
    .recommended_div {
        padding: 0px 30px;
    }
    a.mail_support {
        padding-left: 30px;
    }
    .three-and-four-step>:nth-child(2) {
        width: 250px;
        margin-left: 46px;
        margin-right: 20px;
    }
        
    
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
    .doted-line {
        width: 100%;
    }

    .progress-div-success {
        width: 80%;
        margin-top: 17%;
    }
    .progress-div-success-status {
        width: 100%;
    }
    .progress-div-success-status> :nth-child(2) {
        font-size: 12px;
        color: #f4a70c;
        font-weight: 700;
        margin-left: -19px;
    }
    .progress-div-success-status> :nth-child(3) {
        margin-right: 35px;
        font-size: 12px;
    }
    .progress-div-success-status> :nth-child(1) {
        margin-left: 20px;
        font-size: 12px;
    }
    .success-img-div {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
    .success-img-div>img {
        width: 50% !important;
        margin-top: 2.1% !important;
    }
    .success-btns {
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 90px;
        padding: 0% 6.8%;
    }
    .success-btns>:nth-child(1) {
        width: 100% !important;
        margin-right: 0px !important;
        font-size: 24px !important;
    }
    .success-btns>:nth-child(2) {
        width: 100% !important;
        margin-right: 0px !important;
        font-size: 24px !important;
    }
    .success-coin-data-div {
        display: flex;
        justify-content: center;
        margin-bottom: 22px;
        flex-direction: column;
        align-items: center;
        padding: 15% 6.8% 0%;
    }
    .toicon_desktop{
        display: none;
    }
    .toicon_mobile {
        display: block;
        height: 60px;
    }
    .success-coin-data-div> :nth-child(1) {
        width: 100% !important;
    }
    .success-coin-data-div> :nth-child(1)> :nth-child(2)> span {
        font-size: 16px !important;
    }
    .success-coin-data-div> :nth-child(1)> :nth-child(2)> img {
        width: 24px !important;
        margin-right: 7px !important;
    }
    .success-you-send-amount {
        padding-left: 20px !important;
    }
    .success-you-send-amount> :nth-child(1) {
        font-size: 16px !important;
    }    
    .success-you-send-amount> :nth-child(2) {
        font-size: 12px !important;
    }
    .toicon {
        padding: 30px 0px !important;
    }
    .success-coin-data-div> :nth-child(3) {
        padding: 0% 1.8% !important;
        width: 100% !important;
    }
    .success-coin-data-div> :nth-child(3)> :nth-child(2)> span {
        font-size: 16px !important;
    }
    .success-you-get-amount> :nth-child(1) {
        font-size: 16px !important;
    }
    .success-you-get-amount> :nth-child(2) {
        font-size: 12px !important;
    }
    .success-coin-data-div> :nth-child(1)> :nth-child(2) {
        justify-content: end;
        display: flex;
        align-items: center;
        width: 22%;
        padding-right: 47px;
    }
    .success-coin-data-div> :nth-child(3)> :nth-child(2)> img {
        width: 24px !important;
        margin-right: 7px !important;
    }
    .success-coin-data-div> :nth-child(3) {
        padding: 0% 8.8% !important;
        width: 100% !important;
    }
    .success-completion {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        padding-left: 55px;
    }
    .adressRow_desktop{
        display: none;
    }
    .adressRow_mobile{
        display: block !important;
        width: 100%;
    }
    .adress_deposit {
        text-align: center;
        margin: 0px auto;
        padding-top: 60px;
    }
    .deposit-address-width {
        width: 345px !important;
        overflow: hidden;
    }
    span.react-draggable {
        margin-left: 0px !important;
    }
    .adress_recipient {
        margin: 0px auto;
        text-align: center;
        padding-top: 58px;
    }
    .recipient-address-width {
        width: 345px !important;
    }
    .left-round-div.TX_Hash {
        text-align: center;
        margin: 0px auto;
        padding-top: 50px;
    }
    .txhash-width {
        width: 267px !important;
    }
    span.react-draggable {
        margin-left: 0px;
        font-size: 12px;
        color: #A3A09E;
    }
    .exchange_note {
        padding-left: 5%;
    }
    .tracker_div {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .ordertracker_rounded {
        width: 631px;
        margin: 0px auto;
    }
    .tracker_id {
        text-align: center;
        padding-top: 140px;
    }
    .ordertracker_rounded {
        width: 381px;
        margin: 0px auto;
    }
    .partner_exchange {
        text-align: center;
        padding-top: 55px;
        margin: 0px !important;
    }
    .local_exchange_img {
        margin: 10px auto;
    }
    .floating-box-progress {
        width: 100% !important;
        margin: 0px auto !important;
        margin-right: 0px !important;
        text-align: center !important;
        padding-top: 20px !important;
    }
    .floating_rounded {
        width: 381px;
        margin: 0px auto;
        margin-left: 24px;
    }

    .recommended_div {
        padding: 0px 30px;
    }
    a.mail_support {
        padding-left: 30px;
    }
    .three-and-four-step>:nth-child(2) {
        width: 250px;
        margin-left: 46px;
        margin-right: 20px;
    }
    .three-and-four-step>:nth-child(2)>:nth-child(1){
        display: none !important;
    }
}

@media only screen and (max-width: 430px){

    .progress-div-success{
        width: 75%;
    }

    .success-text-heading{
        font-size: 35px !important;
        margin-bottom: 30px !important;
        margin-top: 25px !important;
    }

    .success-btns{
        gap: 40px;
    }

    .progress-div-success-status> :nth-child(1) {
        font-size: 10px;
        margin-left: 6%;
    }

    .progress-div-success-status> :nth-child(2) {
        margin-left: -5%;
        font-size: 10px;
    }

    .progress-div-success-status> :nth-child(3) {
        margin-right: 10%;
        font-size: 10px;
    }

    .success-btns>:nth-child(1){
        font-size: 18px !important;
    }

    .success-btns>:nth-child(2){
        font-size: 18px !important;
    }

    .success-btns>:nth-child(2)>img{
        font-size: 38%;
        margin-bottom: 6px;
    }

}

@media only screen and (max-width: 375px){
    .progress-div-success-status> :nth-child(2) {
        font-size: 12px;
        color: #f4a70c;
        font-weight: 700;
        margin-left: -7px;
    }
    .progress-div-success-status> :nth-child(3) {
        margin-right: 25px;
        font-size: 12px;
    }
    .success-btns {
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 54px;
        padding: 0% 6.8%;
    }
    .tracker_id {
        margin: 0px auto !important;
        text-align: center;
        padding-top: 105px;
    }
    .deposit-address-width {
        width: 100% !important;
    }
    .recipient-address-width {
        width: 267px !important;
        overflow: hidden;
    }
    .left-round-div.TX_Hash {
        text-align: center;
        margin: 0px auto;
        padding-top: 50px;
        padding-left: 10px;
    }
    .txhash-width {
        width: 197px !important;
    }
    .tracker_id {
        margin: 0px auto !important;
        text-align: center;
        padding-top: 105px;
        padding-left: 32px;
    }
    .ordertracker_rounded {
        width: 328px !important;
        margin: 0px auto !important;
    }
    .tracker_id {
        margin: 0px auto !important;
        text-align: center;
        padding-top: 105px;
        padding-left: 25px;
    }
    .floating-box-progress {
        width: 100%;
        margin: 0px auto;
        margin-right: 0px;
        text-align: center;
        padding-top: 20px;
        padding-left: 25px;
    }
    .floating_rounded {
        width: 328px;
        margin: 0px auto;
    }
    .adressRow_mobile {
        display: block !important;
        width: 100%;
    }
}
@media only screen and (max-width: 320px){
    .adressRow_mobile {
        display: block !important;
        width: 100%;
    }
    .success-completion {
        padding-left: 30px;
    }
}