body{
  overflow-x: hidden;
}
.enter-address-text-div> :nth-child(1){
    width: 50px;
    position: absolute;
    left: 15px;
  }
  
  .enter-address-text-div> :nth-child(2){
    font-size:36px;
    font-weight: bold;
    color: white;
    font-family: 'basementgrotesque';
  }
  
  .enter-address-text-div> :nth-child(3){
    font-size:36px;
    font-weight: bold;
    color: #f4a70c;
    font-family: 'basementgrotesque';
  }
  
  .enter-address-text-div> :nth-child(4){
    font-size:17px;
    display: block;
    /* font-weight: bold; */
    color: #928F8C;
  }
  
  .enter-address-text-div> :nth-child(5){
    font-size:17px;
    display: block;
    /* font-weight: bold; */
    color: #928F8C;
  }
  #thumb-cursor {
    cursor: pointer !important; /* Changes cursor to a hand/thumb icon */
  }
  .send-coin-logo-submit-deposit{
    width: 30px;
    margin-bottom: 5px;
  }
  .submit-partner-logo{
    width: 230px;
  }
  .submit-data{
    font-size: 20px;
  }  
  .rounded-div{
    color: white;
      border: 1px solid white;
      border-radius: 30px;
      padding: 12px 20px;
      margin-top: 13px;
      text-align: center;
  }
  .rounded-div-align-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rounded-div-tx{
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    padding: 12px 20px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .submit-deposit-address-width{
    width: 300px;
    overflow: hidden;
  }
  .submit-li::marker{
    color: white;
  }

  .submit-li-extraid::marker{
    color: white !important;
  }
  
  .submit-li{
    color: #928F8C;
    font-size: 15px;
    margin: 15px 0px;
  }
  .QRadress_mobile{
    display: none;
  }
  .copy-icon{
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  .recipient-address-submit-width{
    width: 380px;
    overflow: hidden;
  }
  
.recipient-input-how-to> :nth-child(1){
    display: block;
    color: white;
    font-weight: bold;
    font-family: 'basementgrotesque';
    font-size: 33px;
  }
  
  .recipient-input-how-to> :nth-child(2){
    color: #928F8C;
    font-size: 17px;
  }
  
  .recipient-input-how-to> :nth-child(3){
    display: block;
    color: #928F8C;
    font-size: 17px;
  }
  
.three-and-four-step>:nth-child(1)>img{
    width: 120px;
  }
  .three-and-four-step>:nth-child(2){
    width: 250px;
    margin-left: 130px;
    margin-right: 20px;
  }
  .three-and-four-step>:nth-child(3)>img{
    width: 120px;
  }
  .three-and-four-step>:nth-child(1){
    position: relative;
  }
  .three-and-four-step>:nth-child(1)> div{
    width: 260px;
    position: absolute;
    top: 52px;
    left: 45px;
  }
  
  .three-and-four-step>:nth-child(3){
    position: relative;
  }
  .three-and-four-step>:nth-child(3)> div{
    width: 260px;
    position: absolute;
    top: 52px;
    left: 54px;
  }
  
  .three-and-four-step>:nth-child(1)> div> :nth-child(1){
    display: block;
    color: white;
    font-family: 'basementgrotesque';
    font-size: 23px;
  }
  
  .three-and-four-step>:nth-child(1)> div> :nth-child(2){
    display: block;
    color: #928F8C;
    font-size: 16px;
  }
  .three-and-four-step>:nth-child(1)> div> :nth-child(3){
    display: block;
    color: #928F8C;
    font-size: 16px;
    margin-top: -5px;
  }
  .three-and-four-step>:nth-child(1)> div > :nth-child(4){
    display: block;
    color: #928F8C;
    font-size: 16px;
    margin-top: -5px;
  }
  
  .three-and-four-step>:nth-child(3)> div> :nth-child(1){
    display: block;
    color: white;
    font-family: 'basementgrotesque';
    font-size: 23px;
  }
  
  .three-and-four-step>:nth-child(3)> div> :nth-child(2){
    display: block;
    color: #928F8C;
    font-size: 16px;
  }
  .three-and-four-step>:nth-child(3)> div> :nth-child(3){
    display: block;
    color: #928F8C;
    font-size: 16px;
    margin-top: -5px;
  }
  .three-and-four-step>:nth-child(3)> div > :nth-child(4){
    display: block;
    color: #928F8C;
    font-size: 16px;
    margin-top: -5px;
  }
  
  .three-and-four-step{
    width: 700px;
      display: flex;
      align-items: center;
      margin: auto;
      margin-top: 100px;
      margin-bottom: 200px;
  }
  
  