.exchange_heading{
    font-family: basementgrotesque;
    color: #fff;
}
.exchange_para{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    color: #FFFFFF;

}
/*echange_section styling start*/
section.echange_section {
    padding: 7% 0%;
}
h1.list_heading {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
}
.list_heading strong{
    color: #F4A70B;
}
p.list_para {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    width: 965px;
    margin: 0px auto;
}
/*echange_section styling end*/  

/*echange_logo_section styling start*/
  .logo-section {
    display: flex;
    flex-direction: column;
    gap: 20px; 
  }
  .logo_row {
    justify-content: center;
    display: flex;
    gap: 20px;
}
.logo-container {
    background-color: #191816;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 328px;
    height: 100px;
}
.logo-container img {
    max-width: 250px;
    max-height: 100%;
    object-fit: contain;
}
/*echange_logo_section styling end*/

/*Reasons_To_List section styling start*/
section.reasons_section {
    padding: 7% 0%;
}
.reasons-to-list h2 {
    font-size: 28px;
    font-weight: 800;
    line-height: 33.6px;
}
.reasons-to-list {
    text-align: center;
}
  .reasons-to-list h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .reasons-to-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0px auto;
    width: 628px;
}
.reasons-to-list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    color: #fff;
    font-family: basementgrotesque;
}
.reasons-to-list li img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}
/*Reasons_To_List section styling end*/

/*Mail section styling start*/
h1.Email_heading {
    font-size: 28px;
    font-weight: 800;
    line-height: 33.6px;
}
img.mail_image {
    margin: 40px 0px 20px 0px;
    width: 48px;
    height: auto;
}
h4.partnership_mail a{
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    color: #F4A70B;
    text-decoration: none;
}
/*Mail section styling end*/
  
  