/* Media query for small screnn 1024 screen*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    p.list_para {
        width: 895px;
        margin: 0px auto;
    }
    .logo-container {
        width: 295px;
        height: 100px;
    }
    .logo-container img {
        max-width: 190px;
        max-height: 100%;
        object-fit: contain;
    }
}
/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
    p.list_para {
        width: 681px;
        margin: 0px auto;
    }
    .logo-container {
        background-color: #191816;
        padding: 20px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
    }
    .logo-container img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    section.echange_section {
        padding: 7% 0%;
    }
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
    p.list_para {
        width: 100%;
        margin: 0px auto;
    }
    .logo-container {
        background-color: #191816;
        padding: 20px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        margin: 0% 4%;
    }
    .logo-container img {
        max-width: 200px;
        max-height: 100%;
        object-fit: contain;
    }
    section.reasons_section {
        padding: 30% 0% 25% 0%;
    }
    .reasons-to-list ul {
        list-style-type: none;
        padding: 0;
        margin: 0px auto;
        width: 100%;
        text-align: left;
    }
}
/* Media query for small mobile devices*/
@media only screen and (max-width: 375px){
    p.list_para {
        width: 320px;
        margin: 0px auto;
    }
    .reasons-to-list ul {
        list-style-type: none;
        padding: 0;
        margin: 0px 15px;
        width: 100%;
        text-align: left;
    }
}
/* Media query for E-small mobile devices*/
@media only screen and (max-width: 320px){
    p.list_para {
        width: 298px;
        margin: 0px auto;
    }
    .reasons-to-list li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: 13px;
        font-weight: 800;
        line-height: 19.2px;
        color: #fff;
        font-family: basementgrotesque;
    }
    .reasons-to-list h2 {
        font-size: 25px;
    }
}