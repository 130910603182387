
/* Media query for Extra large screen above 1600*/
@media only screen and (min-width: 1601px) {
    .about_coinoswap_item {
        width: 86%;
    }
    .about_coinoswap_item-heading {
        font-size: 35px;
    }
    .about_coinoswap_item_img img {
        width: 32px;
        height: 32px;
    }
    .faq-content h3 {
        font-size: 32px;
    }
    .faq-content p {
        font-size: 23px;
    }
    h2.content_nav_heading {
        font-size: 35px;
    }
    .content_nav .nav-link {
        font-size: 32px;
    }
    .content_nav .nav-icon {
        width: 30px;
        height: 30px;
    }
    #navbar::before {
        width: 5px;
        height: 230px;
        content: '';
        background-size: cover;
        position: absolute;
        left: 3%;
        opacity: 1;
        top: 16%;
    }
}
/* Media query for small screnn 1440 screen*/
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  
}
/* Media query for small screnn 1024 screen*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    #navbar::before {
        width: 6px;
        height: 230px;
        content: '';
        background-size: cover;
        position: absolute;
        left: 6%;
        opacity: 1;
        top: 17%;
    }
    .about_coinoswap_item {
        width: 632px;
    }
    .faq-content p {
        width: 550px;
    }
    .content_nav {
        padding-left: 5%;
    }
}
/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
    .position_class{
        display: none;
    }
    .about_coinoswap_item {
        width: 100%;
    }
    .faq-content p {
        width: 100%;
    }
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
    .position_class{
        display: none;
    }
    .asked_questions_inner-content h2 {
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
        padding: 0% 10%;
    }
    .about_coinoswap_item-heading {
        font-size: 20px;
        line-height: 24px;
    }
    .about_coinoswap_item {
        display: flex;
        align-items: start;
        margin-bottom: 10%;
        gap: 2%;
        background-color: #fff;
        padding: 1.5% 5%;
        width: 100%;
        border-radius: 12px;
        padding-top: 3.5%;
    }
    .about_coinoswap_item_img img {
        width: 20px;
        height: 20px;
        margin-bottom: 21%;
    }
    .faq-content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        width: 100%;
    }
    .faq-content h3 {
        margin: 0 0 10px;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
        text-transform: capitalize;
    }
    .faq_img img {
        height: 40px;
        width: 40px;
    }
}
