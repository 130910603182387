.App {
  text-align: center;
}
body{
  overflow-x: hidden;
}

.tawk-icon-right>i{
  display: none !important;
}

.hid{
  display: none !important;
}

.tawk-icon-right>img{
  width: 90px !important;
  height: 86px !important;
}

.paste-btn{
  position: absolute;
  top: 26%;
  right: 2.5%;
  color: #cc880c;
  background: #FEE4D8;
  border-radius: 10px;
  width: 60px;
  font-size: 11px;
}

.no-scroll {
  overflow: hidden;
  height: 100%; /* Ensures no scrollbars appear */
}

.privacy-txt:hover{
  text-decoration: none;
  color: #ef960d !important;
}


/* .get-wallet-container {
  overflow: hidden;
}

.get-wallet{
  filter: drop-shadow(0px 100px 0 white);
}


.get-wallet:hover {  
  filter: drop-shadow(0px 100px 0 #ef960d) !important;
  transform: translateY(-100px);
} */


.get-wallet:hover {
  filter: invert(61%) sepia(50%) saturate(1504%) hue-rotate(358deg) brightness(120%) contrast(92%);
}



.paste-btn:focus{
  box-shadow:unset;
}

.social-links{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
}

.fa-brands{
  font-size: 120%;
}

.contact_social{
  font-size: 85%;
}

.social-links:hover{
  text-decoration: none;
}

.recipient-input-how-to{
  margin-top: 9%;
  text-align: center;
}

.card_icon{
  width: 27px;
  margin-left: 10px;
  margin-bottom: 1px;
}

.tracker-input:focus{
  background-color: #181818;
  border-color:#484141;
  box-shadow:unset;
  color: white;
}

.tracker-input:focus{
  outline: unset;
}

.chainname-deposit-page{
  font-size: 10px;
    color: red !important;
    border: 1px solid red;
    border-radius: 4px;
    padding: 0px 5px;
    font-weight: 400 !important;
    margin-left: 10px;
    margin-right:10px
}

.recipient-get-coin-logos>:nth-child(1){
  margin-left: 10px;
}

.recipient-send-coin-logos>:nth-child(1){
  margin-left: 10px;
}

.exchange-box-div>:nth-child(1)>div{
  font-size: 10px;
  background-color: rgb(237, 49, 7);
  width: 90px;
  height:19px;
  display: none;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: system-ui;
  color: white;
  border-radius: 10px;
  position: absolute;
  right: 14.5%;
  top: -9px;
}

.offers-type-tab>:nth-child(2)>div{
  font-size: 10px;
  background-color: rgb(237, 49, 7);
  width: 87px;
  height:17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: system-ui;
  color: white;
  border-radius: 10px;
  position: absolute;
  right: 14.5%;
  top: -9px;
}

.exchange-box-div>:nth-child(1){
  position: relative;
}

.dummy-exchange-link{
  width: 170px;
}
.offer-exchangebox-parent-div{
  z-index: 1;
}
.exchangebox-size-adjust{
  display: flex;
  justify-content: end;
  width: 97%;
}

.exchangebox-align{
  display: flex;
  justify-content: end;
}
.navbar-expand-lg{
  padding: 20px 40px;
}

.flag-drop-icon{
  margin:auto 9px; 
  margin-right: 0px; 
  color:white; 
  font-size: 13px; 
  color:#4B4A49; 
  margin-left:17px
}

.trustpilot-img{
  display: flex;
  justify-content: center;
  width: 150px;
  margin-bottom: 3px;
}

.trustpilot-img>img{
  width: 100px;
}
/*
.floating-box-progress{
  width:100%; 
  margin:0px 20px;
  margin-right: 52.7%;
}
*/

.success-partner-logo{
  width: 230px;
}
/*

.left-round-div{
  margin-top: 4%;
}*/




/*
.submit-li::marker{
  color: white;
}

.submit-li{
  color: #928F8C;
  font-size: 15px;
  margin: 15px 0px;
}
*/
.exchange-partner-recipient-input{
  width: 300px;
  margin-left: 11px;
}
/*
.trx-type-submit>:nth-child(1){
  width: 28px;
  margin-right: 12px;
}

.trx-type-submit>:nth-child(2){
font-weight: bold;
font-size: 18px;
}

*/
.deposit-input:focus{
  border-color: black;
  box-shadow: unset;
}

.validation-check{
  color: red;
    font-weight: bold;
    font-size: 14px;
    padding-left: 20px;
    margin-top: -10px;
}

.exchange-type-recipient-input> div> img{
  width: 23px;
  margin-right: 5px;
}

#email-input{
  margin-bottom: 10px;
}
.guide-div{
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.guide-div>:nth-child(1){
  width: 45px;
  margin-right: 25px;
}

.guide-div>div{
  font-size: 16px;
  font-weight: 500;
}

.guide-div>div>:nth-child(1){
  color: #928F8C;
  
}

.guide-div>div>:nth-child(2){
  color: #F0970D;
}

.guide-div>div>:nth-child(3){
  color: #928F8C;
  
}
.guide-div>div>:nth-child(4){
  color: #928F8C;
}

.exchange-partner-recipient-input-div{
padding-left: 40px;
padding-top: 42px;
}

.recipient-input-bullet-1{
  margin-top: 50px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}


.recipient-input-bullet-2{
  font-size: 16px;
  font-weight: 500;
  display: flex;
}



.recipient-input-bullet-1>:nth-child(1){
  font-size: 11px;
  color: white;
  margin-right: 20px;
  margin-top: 5px;
}

.recipient-input-bullet-1>:nth-child(2)>:nth-child(1){
  color: #F0970D;  
}

.recipient-input-bullet-1>div>:nth-child(2){
  color:  #928F8C;
}

.recipient-input-bullet-1>div>:nth-child(3){
  color:  #928F8C; 
}

.recipient-input-bullet-2>:nth-child(1){
  font-size: 11px;
  color: white;
  margin-right: 20px;
  margin-top: 5px;
}

.recipient-input-bullet-2>:nth-child(1){
  font-size: 11px;
  color: white;
}

.recipient-input-bullet-2>:nth-child(2){
  color: #928F8C;
}

.exchange-type-recipient-input> div{
  width: 220px;
    color: white;
    font-size: 20px;
    border: 1px solid white;
    border-radius: 25px;
    height: 45px;
    padding: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-weight: bold;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(185%/7) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 2s steps(4) infinite;
  margin-left: 2px;
  margin-bottom: -7px;
}

.progress_loader {
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#f4a70c 90%,#f4a70c) 0/calc(185%/7) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 2s steps(4) infinite;
  margin-left: 2px;
  margin-left: 5px;
}

@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

.exchange-partner-recipient-input> img{
  width: 220px;
  margin-top: 15px;
}
.exchange-partner-recipient-input> span{
  color: white;
  font-size: 18px;
  font-family: 'basementgrotesque';
}

.exchange-type-recipient-input> span{
  color: white;
  font-size: 18px;
  font-family: 'basementgrotesque';
}

.recipient-input-label {
  position: absolute;
  top: -10px;
  z-index: 1;
  left: 2em;
  background-color: white;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.grey-line-div{
  width: 180px;
}
.recipient-amount-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipient-send-coin-logos{
  border: 1px solid black;
    border-radius: 8px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 5px;
    width: 160px;
}

.recipient-send-coin-logos> span{
  color: black;
  font-weight: bold;
  margin-left: 5px;
}

.recipient-get-coin-logos{
  border: 1px solid black;
    border-radius: 8px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 5px;
    width: 160px;
}

.recipient-get-coin-logos> span{
  color: black;
  font-weight: bold;
  margin-left: 5px;
}

.recipient-send-amount-div{
  text-align: center;
  margin-left: 20px;
}

.recipient-send-amount-div> :nth-child(1){
  display: block;
  color: #4B4A49;
  font-weight: 500;
  margin-bottom: 5px;
}

.recipient-send-amount-div> :nth-child(2){
  font-family: 'basementgrotesque';
  color: black;
  font-size: 1rem;
}

.recipient-get-amount-div{
  text-align: center;
  margin-right: 20px;
}
.recipient-get-amount-div> :nth-child(1){
  display: block;
  color: #4B4A49;
  font-weight: 500;
  margin-bottom: 5px;
}

.recipient-get-amount-div> :nth-child(2){
  font-family: 'basementgrotesque';
  color: black;
  font-size: 1rem;
}

.glowing-image{
  animation: glow 1s ease-in-out infinite alternate;
}

.proceed-exchange-btn{
  width: 100%;
  background-color: #F0970D;
  border-radius: 10px;
  height: 60px;
  color: black;
  font-weight: bold;
  font-size: 17px;
  border: unset;
  margin-top: 15px;
  font-family: 'basementgrotesque';
}

.proceed-exchange-btn:focus{
  box-shadow: unset;
}

.policy-check{
  display: flex;
}

.policy-check> div> :nth-child(1){
  color: #4B4A49;
  font-size: 14px;
  margin-left: 20px;
}

.policy-check> div> :nth-child(2){
  color: #f4a70c;
  font-size: 14px;
}

.policy-check> div> :nth-child(3){
  color: #4B4A49;
  font-size: 14px;
}

.policy-check> div> :nth-child(4){
  color: #f4a70c;
  font-size: 14px;
}

.wallet-input-div{
  padding: 20px;
  padding-bottom: 25px;
  background-color: white;
  border-radius: 35px;
}
.input-wrapper > input{
  border: 1px solid black;
  height: 75px;
  border-radius: 15px;
  margin: 25px 0px;
}

.input-wrapper{
  position: relative;
}

.input-wrapper > input::placeholder{
  font-size: 15px;
  font-weight: bold;
  color: #4B4A49;
  padding-left: 10px;
}

#flexCheckChecked{
  margin-left: 2px;
  height: 18px;
  width: 18px;
}

@keyframes glow{

    from {
      filter: drop-shadow(0 0 0.75rem rgb(255, 217, 0));
    }
    
    /* to {
      filter: drop-shadow(0 0 0.75rem rgb(255, 217, 0));
    } */
  
}

.progress-div-create{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 880px;
    margin: auto;
}

.progress-div-create-status{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 880px;
    margin: auto;
    margin-top: 20px;
}

.progress-div-create-status> :nth-child(1){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -43px;
}

.progress-div-create-status> :nth-child(2){
  color: white;
  font-weight: 700;
  margin-left: -40px;
}

.progress-div-create-status> :nth-child(3){
  color: white;
  font-weight: 700;
  margin-right: -20px;
}

.progress-div-awaiting{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 880px;
    margin: auto;
}

.progress-div-awating-status{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 880px;
    margin: auto;
    margin-top: 20px;
}

.progress-div-awating-status> :nth-child(1){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -43px;
}

.progress-div-awating-status> :nth-child(2){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -40px;
}

.progress-div-awating-status> :nth-child(3){
  color: white;
  font-weight: 700;
  margin-right: -20px;
}


.progress-div-processing{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 880px;
    margin: auto;
}

.progress-div-processing-status{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 880px;
    margin: auto;
    margin-top: 20px;
}

.progress-div-processing-status> :nth-child(1){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -43px;
}

.progress-div-processing-status> :nth-child(2){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -40px;
}

.progress-div-processing-status> :nth-child(3){
  color: #f4a70c;
  font-weight: 700;
  margin-right: -20px;
}

.progress-div-success{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 880px;
    margin: auto;
    margin-top: 2%;
}

.progress-div-success-status{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 880px;
    margin: auto;
    margin-top: 20px;
}

.progress-div-success-status> :nth-child(1){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -43px;
}

.progress-div-success-status> :nth-child(2){
  color: #f4a70c;
  font-weight: 700;
  margin-left: -40px;
}

.progress-div-success-status> :nth-child(3){
  color: #f4a70c;
  font-weight: 700;
  margin-right: -20px;
}

.doted-line{
  padding: 0% 4%;
}

.offer-tab-container{
  display: flex;
}

.refreshbtn:hover{
  color: #F0970D;
}

.min-amount:hover{
  color: #F0970D;
}

.min-amount:hover > img{
  filter: brightness(0) saturate(100%) invert(40%) sepia(100%) saturate(500%) hue-rotate(10deg) brightness(135%) contrast(97%);
}


.min-amount:hover img + label {
  color: #F0970D;
  filter: brightness(0) saturate(100%) invert(40%) sepia(100%) saturate(500%) hue-rotate(10deg) brightness(135%) contrast(97%);

}


.clock-refresh-btn-container{
  display: flex;
  width: 30%;
  justify-content: end;
}

.exchange-send-div-input-drop-box img{
  width:30px;
}

.exchange-get-div-input-drop-box img{
  width:30px;
}

.coins-drop-underline{
  background: radial-gradient(circle, rgba(211,211,211,1) 66%, rgba(255,249,249,1) 100%);
}

.segment, .progress {
  fill: none;
  stroke-width: 3px; /* Circle stroke width */
  stroke-linecap: round; /* Make the ends rounded */
}

.track {
  stroke: #ffffff;
}

.progress {
  stroke: linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%);;
  stroke-dasharray: 232.2; /* Circumference of circle with radius 37px (2 * π * 37) */
  stroke-dashoffset: 232.2;
  transition: stroke-dashoffset 10s linear; /* 10-second countdown */
}

.start-timer {
  background-color: linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%)1;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.refreshbtn{
  color: white;
  font-family: basementgrotesque;
  font-size: 12px;
  background-color: transparent;
  border: none;
  
}

.timer-circle-div{
  margin-left: 3.9%;
  height:50px;
  display: flex;
  align-items: center;
}

.refreshbtn-div{
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 2%;
}

.nooffer-div{
  height: 90px;
  color: #887d7d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4a4545;
  border-radius: 20px;
  font-size: 15px;
  font-family: 'basementgrotesque';
  background: #161515;
}

.network-div{
  border: 1px solid white;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  padding: 0px 5px;
  font-size: 9px;
}

.our-process-desktop{
  /* width: 1390px;
  margin: auto; */
  margin-top: 2%;
}

.footer-about-div{
  padding-left: 0px !important;
}


.App-logo {
  height: 4vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  width: 20%;
  padding-top: 1%;
}

.inline-block{
  display: block;
}

.footer-links-partners-div{
  display: flex;
  justify-content: end;
  padding-right: 0px;
}

.footer-links-div{
  display:flex;
  justify-content: center;
}

.supported-coins div{
  margin-left: -20%;
}

.flag{
  /* width: 50%; */
  display: flex;
  /* margin-right: 15px; */
  justify-content:end;
}

#flag-drop-down{
  margin-right: 0px;
  display: flex;
  justify-content: end;
}

.navbar-links{
  margin:0px auto;
}

.nav-link{
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  color: rgb(110 97 97 / 90%) !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}


.txt-white{
  color: white;
}

.font-grey{
  color: #93918E !important;
}

.footer{
  margin:0% 1%;
  margin-bottom: 5%;
}

.footer-container{
  padding-top: 5%;
  margin-top: 7%;
background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.01584383753501406) 0%);
}



@font-face {
  font-family: basementgrotesque;
  src: url(../public/BasementGrotesque-Black_v1.202.otf);
}

.basement-font{
  font-family: basementgrotesque;
}

.footer-headings{
  font-size: 20px;
  font-weight: 800;
}

.exchange-box-div{
  background-color: white;
  border-radius: 30px;
  padding-top: 3.2%;
  padding-bottom: 0.5px;
  width: 100%;
  aspect-ratio: auto 1 / 0.53;
  z-index: 4;
}

.exchange-box-tab{
  width: 94%;
  aspect-ratio: auto 1 / 0.09;
  margin: 0 3% 3%;
  /* height: 55px; */
  background: #E3E1DE;
  border-radius: 30px;
  display: flex;
}

.type-of-coins-tab{
  height: 35px;
  background: #E3E1DE;
  border-radius: 30px;
  margin: 0% 3%;
  margin-bottom: 20px;
  display: flex;
}

.exchange-inputs-div{
  background: #100F0E;
  border-radius: 30px;
  margin:0% 2.5% 2.5%;
  padding: 3% 2.6%;
}

.exchange-send-div{
  width: 100%;
  aspect-ratio: auto 1/0.17;
  border-radius: 18px;
  border: 1px solid #2A2927;
  display: flex;
}

.exchange-get-div{
  width: 100%;
  aspect-ratio: auto 1/0.17;  border: 1px solid #2A2927;
  border-radius: 18px;
  display: flex;
}

.exchange-box-tab-exchange{
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color:#E3E1DE;
  border: none;
  font-family: basementgrotesque;
  font-size:1.1rem;
}

.exchange-box-tab-exchange:focus{
  background-color: black;
  box-shadow: none;
  color: white;
}

.popular-currencies-btn{
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color:#E3E1DE;
  border: none;
  font-family: basementgrotesque;
  font-size:0.7rem;
}

.popular-currencies-btn:focus{
  background-color: black;
  box-shadow: none;
  color: white;
}

.exchange-box-tab-buy{
  width: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E3E1DE;
  border: none;
  font-family: basementgrotesque;
  font-size:1.1rem;
}

.exchange-box-tab-buy:focus{
  background-color: black;
  box-shadow: none;
  color: white;
}

.stable-currencies-btn{
  width: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E3E1DE;
  border: none;
  font-family: basementgrotesque;
  font-size:0.7rem;
}

.stable-currencies-btn:focus{
  background-color: black;
  box-shadow: none;
  color: white;
}


.other-currencies-btn{
  width: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E3E1DE;
  border: none;
  font-family: basementgrotesque;
  font-size:0.7rem;
}

.other-currencies-btn:focus{
  background-color: black;
  box-shadow: none;
  color: white;
}

#exchange-box-send-input{
  height: 34px;
  width: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding-left: 0px;
}

#exchange-box-send-input:focus{
  box-shadow:none;
}

#exchange-box-get-input{
  height: 34px;
  width: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding-left: 0px;
}

#exchange-box-get-input:focus{
  box-shadow:none;
}

.exchange-send-label{
  margin-bottom: 0px;
  font-size: 13px;
  color: #918F8C;
}

.exchange-get-label{
  font-size: 13px;
  color: #918F8C;
  margin-bottom: 0px;
}

.exchange-send-div-input{
  width: 65%;
  display: flex;
  align-items: center;
}

.exchange-send-div-input>div{
  margin-left: 30px;
  margin-top: -4%;
}



.exchange-send-div-input-drop{
  width: 35%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 20px;
}

.exchange-send-div-input-drop-box{
  width: 100%;
  height: 60%;
  background: #161515;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchange-get-div-input{
  width: 65%;
  display: flex;
  align-items: center;
}

.exchange-get-div-input>div{
  margin-left: 30px;
  margin-top: -4%;
}

.exchange-get-div-input-drop{
  width: 35%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 20px;
}

.exchange-get-div-input-drop-box{
  width: 100%;
  background: #161515;
  height: 60%;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-amount{
  margin-bottom: 0px;
  margin-left: 13px;
  font-size: 12px;
  color: #918F8C;
}

.est-amount{
  margin-bottom: 0px;
  margin-left: 13px;
  font-size: 12px;
  color: #EF980F;
}

.swap-btn-div{
  display: flex;
  justify-content: center;
  margin:-0.5%;
  height: 7%;
}

.swap-btn{
  width: 40px;
    height: 40px;
    background: white;
    margin: -1.3%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-btn{
  width: 100%;
  font-family: basementgrotesque;
  font-size: 20px;
  color: black;
  background: rgb(244,107,12);
  background: linear-gradient(0deg, rgba(244,107,12,1) 27%, rgba(240,168,15,1) 97%);
  border-radius: 13px;
    aspect-ratio: auto 1 / 0.1;
}

.offer-bottom{
  height: 81px;
}

.navbar-brand{
  margin-right: 0.5rem;
}

.section1-home{
  padding: 2% 2%;
  margin-top: 2%;
}

.section1-home>:nth-child(1){
  padding-top: 2.5%;
}

.section1-home-headding{
  font-family: basementgrotesque;
  color: white;
  font-size: 4.4vw;
}

.why-coinoswap-heading{
  font-family: basementgrotesque;
  color: white;
  text-align: center;
}

.why-coinoswap-div{
  margin-top: 70px;
}


.partners-slider{
  font-family: basementgrotesque;
  color: white;
  text-align: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;


  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
  /* -webkit-filter: brightness(0) saturate(100%) invert(34%) sepia(6%) saturate(2223%) hue-rotate(255deg) brightness(94%) contrast(91%); */
  /* filter: brightness(0) saturate(100%) invert(34%) sepia(6%) saturate(2223%) hue-rotate(255deg) brightness(94%) contrast(91%); */
  /* max-height: 48px; */
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  /* width: 100%; */
  display: none !important;
}

.exchange-pairs{
  margin:0% 1.5%;
}


.copy-right-div{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1.5% 2%;
}

.txt-grey{
  color: #5d5c5b !important;
}

.terms-privacy{
  width: 240px;
  display: flex;
  justify-content: end;
}

.social-icons{
  width: 100%;
  margin:1%;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon-div{
  width: 300px;
  aspect-ratio: auto 1/0.17;
  display: flex;
}

.footer-links{
  padding:4px 0px
}

.footer-links:hover{
  color:#ef960d !important;
  text-decoration: none;
}



/* .social-twitter{
  font-size: 30px;
} 

.social-tiktok{
  font-size: 30px;

} 

.social-instagram{
  font-size: 30px;
} 

.social-youtube{
  font-size: 30px;
} 

.social-facebook{
  font-size: 30px;
}  */

.social-icons:hover >.social-tiktok{
  color:white
}

.social-icons:hover >.social-twitter{
  color:white
}

.social-icons:hover >.social-instagram{
  color:white
}

.social-icons:hover >.social-youtube{
  color:white
}

.social-icons:hover >.social-facebook{
  color:white
}

.social-icons:hover{
  background: rgb(244,107,12);
  background: linear-gradient(0deg, rgba(244,107,12,1) 27%, rgba(240,168,15,1) 97%);
}

.nav-link:hover{
  color:white !important;
}

.order-tracker-anchor:focus{
  color: white !important;
}

.order-tracker-anchor:active{
  color: white !important;
}



/* Dropdown container */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Dropdown toggle button */
.dropdown-toggle {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

/* Dropdown content of home page */
.dropdown-content-send {
  position: absolute;
  z-index: 1;
  display: block;
  background-color: #f9f9f9;
  width: 100%;
  height:500px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 15px;
}


/* Dropdown content of offer exchange box */
.dropdown-content-send-offer {
  position: absolute;
  z-index: 1;
  display: block;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 15px;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  height: 500px;
}


.coin-drop-div{
  height: 60px;
  display: flex;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdown-network-name{
  padding: 0px 5px;
  color: white;
  border-radius: 5px;
  font-size: 0.8rem;
}

.dropdown-content-send{
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.scrollbox{
  overflow-y: scroll;
  height: 85%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.scrollbox::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.scrollbox::-webkit-scrollbar-thumb {
  background: #F0980F;
  border-radius: 10px;
}

.scrollbox::-webkit-scrollbar-track {
  background: #B8B5B1;
  border-radius:10px;
  margin-bottom: 35px;
}

.search-box{
  display: flex;
  align-items: center;
  width: 100%;
}
.privacy-policy{
  margin-left: 10%;
}

.dropdown-content-get-offer {
  position: absolute;
  z-index: 1;
  display: block;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 15px;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  height: 500px;
}

.dropdown-content-get{
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  height: 500px;
  position: absolute;
  z-index: 1;
  display: block;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 15px;
}

.search-icon{
  font-size: 30px;
  color: white;
  padding-left: 6%;
  padding-right: 3%;
}

.input-box{
  background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.input-box::placeholder{
  color:#3e3c37;
  font-size: 22px;
  font-weight: 400;
}

.input-box:focus{
  box-shadow: none;
  background-color: transparent;
  color: white;
}





.why-choose-us-img-mobile{
  display: none;
}



.why-choose-us-img-mobile{
  margin-top:3%;
}

.estimated-amount{
  margin-left: 20px;
}

.currency-get-label-div{
  display: flex;
  align-items: center;
  margin-left: 12px;
}

@keyframes dot-pulse-after { 
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #F0980F;
  color: #F0980F;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #F0980F;
  color: #F0980F;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.1s;
  margin: 15.5% 0px;
  margin-left: 30px;
}
.swap-btn:hover{
  /* background: rgb(244,107,12);
  background: linear-gradient(0deg, rgba(244,107,12,1) 27%, rgba(240,168,15,1) 97%); */
  background-color: rgb(240, 152, 15);
}

.swap-btn:hover img{
  filter: invert(100%) brightness(200%);
}

.pair{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0% 9.5%;
}

.pair-divs-row-1{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
}

.pair-divs-row-2{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 40px;
}

.exchange-pairs h3{
  font-family: basementgrotesque;
  color: white;
  margin-top: 4%;
  margin-bottom: 2%;
}

.footer-logo{
  width: 65%;
  margin-bottom: 9%;
}

.footer-logo>a{
  padding-top: 0px;
}

.payment-logo img{
  width: 70%;
}

.payment-logo h5{
  margin-bottom: 6% ;
}

.flag-menu-btn{
  display: flex; 
  width: 7%;
  justify-content: end;
  align-items: center;
}

#mob-drop-div{
  height:260px;
}

.mob-active-class{
  background-color: #FCE9C3;
    color: #ED3108 !important;
    font-weight: bold;
}

@media screen and (min-width: 1740px) and (max-width: 3000px) {
  .exchange-box-div{
    border-radius: 42px !important;
    padding-bottom: 0px;
  }
  .exchange-box-tab-exchange{
    border-radius: 42px !important;
  }
  .exchange-box-tab-buy{
    border-radius: 42px !important;
  }

  .exchange-box-tab{
    border-radius: 42px;
  }

  .exchange-box-tab-exchange{
    font-size: 1.2rem;
  }

  .exchange-box-tab-buy{
    font-size: 1.2rem;
  }

  .exchange-send-label{
    font-size: 15px;
  }

  .exchange-get-label{
    font-size: 15px;
  }

  #exchange-box-send-input{
    font-size: 35px;
  }

  #exchange-box-get-input{
    font-size: 35px;
  }

  .offer-btn{
    font-size: 22px;
    border-radius: 20px;
  }

  .exchange-box-div-big-screen{
    aspect-ratio: auto 1/0.52;
  }
}

@media screen and (max-width: 1390px) {


  .payment-logo h5{
    font-size:17px ;
  }
  }


/* Footer Responsive CSS */

/* Tablet */
@media screen and (max-width: 993px) {

  .our-process-mobile{
    width: 60% !important;
    margin-left: 2%;
    margin-top: 4%;
  }

  .exchange-pairs h3{
    text-align: center;
    margin-top: 7%;
    margin-bottom: 2%;
  }

  .why-coinoswap-para{
    color: #93918E;
    margin: 1.5% 4%;
  }

  .why-choose-us-img-mobile{
    margin-top: 7%;
    padding: 0% 4%;
  }
  
  .footer-logo{
    margin: auto;
    width: 25% !important;
  }


  .exchange-pairs-mobile{
    display: block !important;
    padding: 0% 3.5%;
  }

  .follow-us-on-social{
    text-align: center;
  }

  .exchange-pairs{
    margin: 0px;
  }

  .footer-headings{
    font-size: 28px;
  }

  .footer-links{
    font-size: 1.2rem;
  }

  .social-icon-div{
    width: unset;
    height: unset;
    justify-content: center;
  }


  .coinoswap-logo-footer-desp{
    font-size: 1.2rem !important;
    text-align: center;
  }

  .terms-privacy{
    width: 35% !important;
    justify-content: end;
  }


  
  .why-choose-us-img-mobile{
    display: block;
  }

  .estimated-amount{
    display: block;
    margin-left: 0px;

  }

  .est-amount{
    font-size: 12px;
  }

  .min-amount
{
  margin-left: 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.min-amount label
{
  margin-left: 13px;
}
  .currency-get-label-div{
    margin-left: 5px;
  }

  .currency-get-label-div i{
    margin: auto 4px !important;
    color: white !important;
  }

  .flag{
    width: 10.5%;
  }
  
  .logo{
    width: 23%;
  }

  .our-process-desktop{
    display: none !important;
  }

  .our-process-mobile{
    display: block !important;
  }

  .how-it-works-div{
    display: block !important;
    margin-top: 5%;
  }

  .how-it-works-div h3{
    font-family: basementgrotesque;
    color: white;
    text-align: center;
  }

  .how-it-works-div p{
    text-align: center;
    color:#93918E;
    font-size: 20px;
  }

  .coinoswap-logo-footer-desp{
    color: #93918E !important;
    margin-left:17%;
    margin-right: 17%;
    margin-top: 1.5%;
    margin-bottom: 5%;
  }
}



.offerlogo-offertype{
  display: flex;
  justify-content: space-around;
  width: 52%;
}

.eta-kyc{
  display: flex;
  justify-content: space-around;
  width: 45%;
}

/* .flag-menu-btn{
  width: 50%;
} */



@media screen and (max-width: 430px) {

  .exchangebox-align{
    margin-top: 15px !important;
  }

  .paste-btn{
    top: 30%;
    right: 3%;
    width: 19%;
    font-size: 12px;
  }

  .contact_social{
    font-size: 85% !important;
  }

  .supported-coins div{
    margin-left: 0%;
  }

  .exchangebox-wrapper{
    width: 100% !important;
  }

  .section1-home-headding> :nth-child(1), .section1-home-headding> :nth-child(2), .section1-home-headding> :nth-child(3){
    display: inline-block;
  }

  .section1-home-headding>:nth-child(2){
    margin-left: 8px;
  }

  .chainname-deposit-page{
    font-size: 8px;
  }

  .offers-type-tab>:nth-child(2)>div{
    width: 71px;
    height: 15px;
    font-size:8px;
  }

  .exchange-box-div>:nth-child(1)>div{
    font-size: 10px;
    width: 85px;
    height:17px;
    right: 10%;
  }

  .card_icon{
    width: 17px;
    margin-left: 5px;
    margin-bottom: 1px;
  }

  .exchangebox-size-adjust{
    width: 100%;
  }

  .exchangebox-align{
    display: block;
  }

  .navbar-expand-lg{
    padding: 25px 20px;
    padding-bottom: 10px;
  }

  .section1-home{
    margin-top: 0%;
  }

  .exchange-send-div-input{
    width: 60%;
  }

  .exchange-send-div-input-drop{
    width: 40%;
  }

  .exchange-get-div-input{
    width: 60%;
  }

  .exchange-get-div-input-drop{
    width: 40%;
  }

  .exchange-send-div-input-drop{
    margin-right: 10px;
  }

  .exchange-get-div-input-drop{
    margin-right: 10px;
  }

  .min-estimate-div{
    display: block !important;
  }

.flag-drop-icon{
  margin: 0px 10px;
  display: flex;
  align-items: center;
}

  .exchange-type-recipient-input> div> img {
    width: 15px;
  }

  .enter-address-text-div> :nth-child(2){
    display: block;
    font-size: 32px;
  }

  .enter-address-text-div> :nth-child(3){
    display: block;
    font-size: 32px;
  }

  .enter-address-text-div> :nth-child(4){
    display: inline;
  }

  .enter-address-text-div> :nth-child(5){
    display: inline;
  }

  .doted-line{
    width:250px
  }

  .progress-div-create> :nth-child(1){
    width: 70px;
  }

  .progress-div-create> :nth-child(3){
    width: 70px;
  }

  .progress-div-create> :nth-child(5){
    width: 70px;
  }

  .recipient-send-amount-div> :nth-child(2){
    font-size: 0.7rem;
  }

  .recipient-get-amount-div> :nth-child(2){
    font-size: 0.7rem;
  }

  .recipient-send-amount-div> :nth-child(1){
    font-size: 12px;
    margin-bottom: 0px;
  }

  .recipient-get-amount-div> :nth-child(1){
    font-size: 12px;
    margin-bottom: 0px;
  }


  .grey-line-div{
    width: 50px;
  }

  .exchange-type-recipient-input{
    width: 150px;
  }

  .exchange-partner-recipient-input> img{
    width: 150px;
  }

  .recipient-input-bullet-1{
    font-size: 14px;
  }

  .recipient-input-bullet-2{
    font-size: 14px;
  }

  .guide-div>div{
    font-size: 14px;
  }

  .guide-div>:nth-child(1){
    width: 90px;
  }


  .exchange-partner-recipient-input-div>:nth-child(1){
    justify-content: space-around;
  }
  
  .enter-address-text-div{
    padding: 0% 10%;
  }

  .enter-address-text-div> :nth-child(3){
    font-size: 16px;
  }

  .enter-address-text-div> :nth-child(4){
    font-size: 16px;
  }

  .coin-drop-div div:nth-child(1) > img{
    width: 25px !important;
    height: 25px !important;
  }

  .input-box::placeholder{
    color:#3e3c37;
    font-size: 15px;
    font-weight: 400;
  }

  #stable-get{
    font-size: 12px;
  }

  #other-get{
    font-size: 12px;
  }

  .input-box{
    font-size: 15px;
  }

  .dropdown-content-send{
    top:40%
  }

  .dropdown-content-get{
    top: 59%;
  }

  .flag-menu-btn{
    width: 45%;
  }

  .fixed-float-tab{
    font-size: 0.8rem;
  }

  .offer-type{
    font-size: 0.6rem !important;
  }

  .refreshbtn-div{
    width: 30%;
  }

  .clock-refresh-btn-container{
    display: flex;
    justify-content: end;
    width: 100%;
  }

  .offer-tab-container{
    display: block;
  }
  .dot-pulse{
    margin: 12% 0%;
    margin-left: 25px;
  }

  .network-div{
    margin-left: 5px;
    font-size: 8px;
  }

  #popular-send{
    font-size: 12px !important;
  }

  #popular-get{
    font-size: 12px !important;
  }

  .mobile-menu-div{
    display: block !important;
  }

  .flag{
    width: 40%;
    margin-right: 5%;
  }

  .mobile-menu-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wallet-ordertracker-div{
    display: none;
  }
  .footer-links-partners-div{
    display: block;
    padding-right: 15px;
    margin-top: 40px;
  }

  .footer-links-div{
    display: block;
  }
  .follow-us-on-social{
    margin-top: 10% !important;
  }
  .exchange-pairs h3{
    margin-top: 10%;
  }
  .coin-logo{
    width: 30px !important;
    height: 30px !important;
  }

  .pairs-row-2{
    margin-top: 0px !important;
  }

  .pair{
    border-right: unset !important;
    border-bottom: 1px solid #555552;
    height: unset !important;
    padding: 0px 5px 17px 5px;
  }

  .pair-divs-row-2 > .pair-4{
    border-bottom: unset;
  }

  .pair-divs-row-1{
    padding: 0px 5px 0px 5px;
    margin-bottom: 22px;
  }

  .arrow{
    width: 35x !important;
  }

  .pair-divs-row-2{
    padding: 0px 5px 0px 5px;
    margin-bottom: 22px;
  }

  .pairs-row-1{
    border-bottom: unset !important;
  }

  .coin-div-width{
    justify-content: start !important;
  }

  .step2-desktop{
    display: none;
  }
  .footer-about-div{
    padding-left: 15px !important;
  }

  .step2-mobile{
    display: block  !important;
    margin: 40px 0px;
  }

  .offer-bottom{
    height: 148px !important;
  }

  .offer-top{
    display: block !important;
    height: 80px !important;
  }

  .offer_logo{
    padding-left: 11px !important;
  }

  .exchange-link{
    width: 100% !important;
    
  }

  .dummy-exchange-link{
    width: 100%;
  }

  .rate-img img{
    width: 75px !important;
  }

  .trustpilot-img>img{
    width: 100px !important;
  }

  .trustpilot-img{
display: flex;
justify-content: center;
width: 150px;
  }

  .offer-rate-txt{
    font-size: 0.9rem !important;
  }
  .offerlogo-offertype{
    width: 100%;
    padding: 3px 5px 5px 0px;
    justify-content: space-between; 
  }

  .eta-kyc{
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
  }

  .offer-div{
    height: 230px !important;
  }

  .offer-type-txt{
    width: 104px !important;
    right: 40px !important;
    height: 12px !important;
    top: 222px !important;
  }

  .offer-type-txt span{
    font-size: 0.6rem !important;
  }

.offers-type-tab{
  height: 37px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}

.clock-refresh-btn-container{
  margin-bottom: 10px;
}

.fixed-float-tab{
  width: 100% !important;
  
}

.offer-type{
  font-size: 0.6rem;
}

.payment-logo h5{
  font-size: 1.05rem;
}

.payment-trustpilot-div{
  margin-bottom: 13%;
}

.payment-logo img{
  width: 170px;
}

.trustpilot-logo{
  justify-content: end !important;
}
  .est-amount{
    margin-top: 15px;
  }

  .exchange-box-div{
    border-radius: 37px;
    padding-top: 19px;
  }

  .exchange-inputs-div{
    margin: 21px 13px;
    margin-bottom: 13px;
  }

  .fixed-float-tab{
    margin-top: 20px !important;
    margin-bottom: 35px !important;
  }

  .copy-right-div{
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .swap-btn{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;  }

  .terms-privacy{
    margin-top: 20px;
  }

  .offer-btn{
    margin-top: 4px;
  }

  .fa-send{
    display: none;
  }

  .fa-get{
    display: none;
  }

  .swap-btn img{
    height: 18px !important;
  }

  .partners-slider{
    margin-top: 24.5% !important;
  }

  .exchange-inputs-div{
    padding: 12px 11px;
  }

  .exchange-get-div{
    margin-top: -6px;
  }

  .exchange-send-div{
    height: 90px;
  }

  .exchange-get-div{
    height: 90px;
  }
  
.exchange-send-div{
  margin-bottom: -6px;
}

  .section1-home-heading-div p{
    font-size: 0.8rem !important;
    color: #93918E !important;
  }

  .social-icon-div {
    margin-top: 1.4rem !important;
    margin: auto;
  }

  .logo{
    width: 50%;
  }



  #mobile-dropdown-menu>img{
    width: 30px !important;
  }

  .how-it-works-div p{
    font-size: 1rem;
  }

  .how-it-works-div{
    margin-top: 8%;
  }

  .how-it-works-div h3{
    font-size: 1.5rem;
  }

  .our-process-mobile{
    width: 90% !important;
    margin-left: 6%;
  }

  .footer-logo {
    width: 70% !important;
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .privacy-txt{
    color: #93918E !important;
    font-size: 1rem;
    text-decoration: none;
  }

  .terms-privacy{
    width: 100% !important;
    justify-content: start;

  }



  .copy-right-div{
    display: block;
  }

#exchange-box-send-input{
padding-right: 0px;
width: 95%;
font-size: 16px !important;
padding-top: 0px;
}

.coinoswap-logo-footer-desp {
  margin-left: 0px;
  margin-right: 0px;
  font-size: 1rem !important;
}

.footer-links{
  font-size: 15px;
  color: #93918E !important;
}

.footer-headings{
  font-size: 20px;
}

.fa-brands{
  font-size: 120%;
}

.social-icon-div{
  width: 310px;
}

.section1-home-heading-div h1{
  font-size: 22px;
}

.currency-send-label-div i{
  color: white;
}

.currency-send-label-div{
  margin-left: 5px !important;
  display: flex;
}

#exchange-box-get-input{
padding-right: 0px;
width: 95%;
font-size: 16px !important;
padding-top: 0px;
}

.exchange-send-div-input>div{
  margin-left: 10px;
}

.exchange-get-div-input>div{
  margin-left: 10px;
}


.exchange-box-tab-exchange{
  font-size: 11px !important;
}

.exchange-box-tab-buy{
  font-size: 11px !important;
}

.exchange-box-tab{
  height: 45px;
}


.send-crypto-img{
  width: 20px !important;
}

.popular-currencies-btn{
  font-size: 40%;
}

.type-of-coins-tab{
  margin-left: 0px;
  margin-right: 0px;
}

.stable-currencies-btn{
  font-size: 40%;
}

.other-currencies-btn{
  font-size: 40%;
}

.get-crypto-img{
  width: 20px !important;
}

.btn-send-label{
  font-size: 12px !important;
}

.btn-get-label{
  font-size: 12px !important;
}

.fa-send{
  font-size: 0.7rem;
  margin: auto 5px;
}

.fa-get{
  font-size: 0.7rem;
  margin: auto 5px;
}

.currency-get-label-div{
  margin-left: 5px;
}

}

.btn-send-label{
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-bottom: 0px;
}

.btn-get-label{
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-bottom: 0px;
}


.currency-get-label-div i{
  color: white;
  margin-left: 10px;
}

.currency-send-label-div{
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.currency-send-label-div i{
  color: white;
  margin-left: 10px;
}

.our-process-desktop{
  display: block;
}

.our-process-mobile{
  display: none;
}

.how-it-works-div{
  display: none;
}

@media screen and (max-width: 409px){
  .footer-headings{
    font-size: 18px;
  }
  .payment-logo h5{
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {

.recipient-send-amount-div> :nth-child(1){
    font-size: 10px;
  }
  
.recipient-get-amount-div> :nth-child(1){
    font-size: 10px;
  }



}

@media screen and (max-width: 380px){

.footer-headings{
  font-size: 16px;
}

.payment-logo h5 {
  font-size: 13px;
}

.footer-links{
  font-size:13px
}

}

.trustpilot-logo img{
  width: 90px;
}

.trustpilot-logo {
  align-items: center;
  display: flex;
  justify-content: center;
}

.payment-trustpilot-div{
  margin-top: 11%;
}

.dropdown-menu{
  right:20% !important;
  left: unset !important;
  height: 340px;
  width: 12rem !important;
    /* height: 340px; */
    height: 140px;
}

#dropdown-tracker-menu{
  right:unset !important;
  top: 60%;
  width: 19rem !important;
  right: unset !important;
  padding: 1% 1%;
  height: unset;
}

.swap-track-btn{
  width: 100% !important;
  margin-top: 4%;
  font-weight: bold;
  color: black;
  background-color: #F0970D;
  border: none;
  border-radius: 7px;
  height: 45px;
  font-size: 14px;
}

.swap-track-btn:focus{
  box-shadow: unset;
}

.swap-track-btn:active{
  background-color: #F0970D !important;
  box-shadow: unset !important;
  color: black !important;
}


.swap-track-btn:hover{
  background-color: #F0970D;
  color: black;
}

#swap-tracker-input{
  background-color: #E2E1DF;
  border: none;
  border-radius: 7px;
  height: 45px;
  font-size: 12px;
}

#swap-tracker-input:focus{
  box-shadow: unset;
}

.dropdown-item:hover{
  background-color: #FCE9C3;
  color: #ED3108;
  font-weight: bold;
}

.dropdown-item{
  color: #928F8C;
  padding-top: 11px;
  padding-bottom: 11px;
}

.lang-img{
  display:inline-block;
  width:30%;
  margin-right:12%;
}

.scrollbox-lang{
  overflow-y: scroll;
  height: 100%;
  scroll-behavior: smooth;

}

.scrollbox-lang::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.scrollbox-lang::-webkit-scrollbar-thumb {
  background: #F0980F;
  border-radius: 10px;
}

.scrollbox-lang::-webkit-scrollbar-track {
  background: #B8B5B1;
  border-radius:10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

#dropdown-tracker-menu-2{
  right:unset !important;
  top: -180px !important;
  left: -9% !important;
  width: 19rem !important;
  right: unset !important;
  padding: 3% 3%;
  height: unset;
}

@media screen and (max-width: 1695px) {

  .scrollbox::-webkit-scrollbar {
    width: 7px;
}

  #dropdown-tracker-menu-2{
    padding: 3.5% 3.5%;
  }
}

button:focus{
  outline: unset !important;
}

.order-tracker-anchor{
  cursor: pointer !important; /* Changes cursor to a hand/thumb icon */

}

.offer-btn:active{
  background:linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%) !important;
}

.offer-div{
  height: 130px;
  border-radius: 20px;
  background-color: white;
  padding: 1px 1px;
}

.offer_logo img{
  width: 110px;
}

.offer_logo{
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  width: 110px;
}

.offer-type{
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer-type-div{
  height: 30px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  width: 100px;
}

.vh-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.eta-time-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.kyc-circle{
  width: 18px;
  margin: 0px 6px;
}

.kyc-div{
  display: flex;
  align-items: center;
  justify-content: left;
  width: 165px;
}

.offers-type-tab{
  height: 50px;
    border-radius: 40px;
    width: 70%;
    margin-left: 0px;
    margin-bottom: 18px;
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid #262523;
}

.fixed-float-tab{
  height: 45px;
  border-radius: 40px;
  width: 62%;
  margin-left: 0px;
  margin-bottom: 18px;
  background-color: rgba(255, 255, 255, 0.06);
  border: 1px solid;
}

.offer-type{
  border-radius: 40px;
}

.exchange-link{
  border: 1px solid transparent;
}

.exchange-link:hover{
  background: linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%) !important;
  text-decoration: none;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 2s;
}

.animate__animated.animate__fadeInLeft {
  --animate-duration: 2s;
}

.offer-top{
  display: flex;
  height: 47px;
}

.step2-mobile{
  display:none;
}

.offer-type{
  font-size: 0.8rem;
}

.min-estimate-div{
  margin: 1.5% 0%;
  align-items: center;
  display: flex;
  width: 100%;
  aspect-ratio: auto 1 / 0.05;
}

@media screen and (max-width:1310px){
.exchange-box-div{
  padding-top: 15px;
  padding-bottom: 0.5px;
}

.exchange-box-tab{
  height:50px;
  margin-bottom: 15px;

}

.exchange-box-tab-exchange{
  font-size: 0.9rem;
}

.exchange-box-tab-buy{
  font-size:0.9rem;
}

.exchange-inputs-div{
  margin-bottom:13px;

}

.send-crypto-img{
  width: 25px;
  height: 25px;
}

.btn-send-label{
  font-size: 15px;
}

.currency-send-label-div i{
  font-size: 0.7rem;

}

.swap-btn-div{
  margin: -6px;
}



.exchange-send-label{
  font-size: 10px;
}

#exchange-box-send-input{
  height: 28px;
  font-size: 25px;
}


.min-amount{
  font-size: 10px;
}

.est-amount{
  font-size: 10px;
}

.get-crypto-img{
  width: 25px;
  height: 25px;
}

.btn-get-label{
  font-size: 15px;
}

.fa-get{
  font-size: 0.7rem;
}


.exchange-get-label{
  font-size: 10px;
}

.offer-btn{
  height: 60px;
  font-size: 15px;
}

#dropdown-tracker-menu-2 {
  top: -20px !important;
  width: 250px !important;
}

.offer-rate-txt{
  font-size: 1rem !important;
}

#exchange-box-get-input{
  font-size: 25px;
  height: 25px;
}

}



.pair-1{
  border-right: 1px solid #555552;
}
.pair-2{
  border-right: 1px solid #555552;
}
.pair-3{
  border-right: 1px solid #555552;
}

.pairs-row-1{
  margin: 0% 1%;
  margin-top: 25px;
  border-bottom: 1px solid #555552;
}

.pairs-row-2{
  margin: 0% 1%;
  margin-top: 1.9%;

}

.footer-border{
  height: 1px;
  background-color: #555552;
}

.pair-divs-row-1{
  filter:grayscale(100%);
}

.pair-divs-row-1:hover{
  filter:grayscale(0%);
}

.pair-divs-row-2{
  filter:grayscale(100%);
}

.pair-divs-row-2:hover{
  filter:grayscale(0%);
}

.pair-divs-row-1:hover{
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.06346288515406162) 100%);
}

.pair-divs-row-2:hover{
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.06346288515406162) 100%);
}

.coin-logo{
  width: 25px;
  height:25px;
}

.coin-text{
  margin-left: 10px;
}

.coin-text span:nth-child(1){
  font-weight: bold;
  color: white;
  margin-bottom: -5px;

}

.coin-text span:nth-child(2){
  font-size: 12px;
  color: #4f4e4e;

}

.arrow{
  width: 30px;
}

.coin-div-width{
  width: 102px;
}

.arrow-div{
  filter: brightness(0.5);
}

.pair-divs-row-1:hover > .pair-1 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-1:hover > .pair-2 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-1:hover > .pair-3 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-1:hover > .pair-4 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-2:hover > .pair-1 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-2:hover > .pair-2 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-2:hover > .pair-3 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-2:hover > .pair-4 > .coin-div-width > .coin-text span:nth-child(2){
  color: white;
}

.pair-divs-row-1:hover > .pair-1 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-1:hover > .pair-2 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-1:hover > .pair-3 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-1:hover > .pair-4 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-2:hover > .pair-1 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-2:hover > .pair-2 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-2:hover > .pair-3 > .arrow-div{
  filter: brightness(100%);
}

.pair-divs-row-2:hover > .pair-4 > .arrow-div{
  filter: brightness(100%);
}
.back_btn{
  display: none;
}

/* Container for the entire order tracker component */
.order-tracker-container {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2; /* Set higher than the ExchangeBox initially */
}

/* Wrapper for the button and tracker */
.order-tracker-btn-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  /* top: 236px; */
  bottom: 70px;
  left: -38px;
  transition: transform 0.5s ease;
  z-index: 1;
}

.order-tracker-btn-wrapper.open {
  transform: translateX(-240px); /* Moves left when open */
}

/* The tracker content (input + button) */
.tracker-content {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
  transition: transform 0.5s ease, opacity 0.5s ease;
  background-color: #202020;
  border-radius: 15px 0 0 15px;
  position: absolute;
  width: 247px;
  /* top: -1px; */
  left: 406px;
  height: 148px;
  border: 2px solid #fff;
  transition: transform 0.5s ease, opacity 0.5s ease;
    /* Yellow outline only on top, right, and bottom using box-shadow */
    box-shadow: 0 -1px 0 0 #F4A70B, /* Top */
    1px 0 0 0 #F4A70B,  /* Right */
    0 1px 0 0 #F4A70B;  /* Bottom */
}
.tracker-content.visible {
  transform: translateX(-376px);
  opacity: 1;
  visibility: visible;
  z-index: 3; /* Ensure it's above ExchangeBox */
}

/* Styling for the image button */
.tracker-logo {
  width: 38px;
  height: 146px;
  cursor: pointer;
  transition: transform 0.5s ease;
  border-radius: 12px 0px 0px 12px;
    /* Yellow outline only on top, right, and bottom using box-shadow */
    box-shadow: 0 -1px 0 0 #F4A70B, /* Top */
    1px 0 0 0 #F4A70B,  /* Right */
    0 1px 0 0 #F4A70B;  /* Bottom */
}
/* Styling for the input field */
.tracker-input {
  width: 100%;
  margin-bottom: 10px;
  height: 50px;
  background-color: transparent;
  border: 1px solid #5E5C5B7A;
  border-radius: 8px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #C5C2BF;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
/* Styling for the button */
.tracker-btn {
  width: 100%;
  background-color: #F4A70B;
  border: none;
  padding: 10px;
  border-radius: 10px;
  color: #000;
  font-family: basementgrotesque;
  font-size: 12px;
  font-weight: 800;
  line-height: 14.4px;
  text-align: center;
  height: 50px;
}
.tracker-btn:hover {
  background-color: #e68a00;
}

/* Wrapper for the ExchangeBox */
.exchangebox-wrapper {
  display: flex;
  justify-content: end;
}


/* Media query for small screnn 1440 screen*/
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .order-tracker-btn-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 318px;
    left: -38px;
    transition: transform 0.5s ease;
    z-index: 1;
}
}
/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
  .order-tracker-btn-wrapper {
    display: none;
}


}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
  .order-tracker-btn-wrapper {
    display: none;
}

.offer-div{
  height:180px;
}

.offer-bottom{
  height: 130px;
}
}
.footerRow_mobile{
  display: none;
}
