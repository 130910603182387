/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
    .progress-div-processing {
        width: 80%;
        margin: auto;
    }
    .progress-div-processing-status> :nth-child(1) {
        margin-left: 35px;
    }
    .progress-div-processing-status {
        width: 100%;
    }
    .progress-div-processing-status> :nth-child(3) {
        margin-right: 55px;
    }
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
    .progress-div-processing {
        width: 80%;
        margin: auto;
    }
    .progress-div-processing-status> :nth-child(1) {
        margin-left: 20px;
        font-size: 12px;
    }
    .progress-div-processing-status> :nth-child(2) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: -26px;
        font-size: 12px;
    }
    .progress-div-processing-status {
        width: 100%;
    }
    .progress-div-processing-status> :nth-child(3) {
        margin-right: 29px;
        font-size: 12px;
    }
}
