.adressRow_mobile{
    display: none;
}
.success-img-div{
    display: flex;
    justify-content: center;
  }
  
  .success-img-div>img{
    width: 10%;
    margin-top: 2.1%;
  }
  .success-text-heading{
    text-align: center;
    color: #f4a70c;
    font-family: basementgrotesque;
    margin-top: 1%;
    font-size: 2.1vw;
    margin-bottom: 1.5%;
  }


  .success-btns{
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
  }
  .success-btns>:nth-child(1){
    background: linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    width: 14%;
    font-family: basementgrotesque;
    margin-right: 50px;
    font-size: 0.9vw;
    aspect-ratio: auto 1 / 0.15;
  }
  
  .success-btns>:nth-child(1):focus{
    box-shadow:unset;
  }
  
  .success-btns>:nth-child(1):active{
    background-image: linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%) !important;
  }
  
  .success-btns>:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    background: #16AB67;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    width: 16%;
    font-family: basementgrotesque;
    font-size: 0.9vw;
    box-shadow:inset;
    aspect-ratio: auto 1 / 0.15;
  }
  
  .success-btns>:nth-child(2):focus{
    box-shadow:unset
  }
  /* .success-btns>:nth-child(2):active{
    background: linear-gradient(0deg, rgba(244, 107, 12, 1) 27%, rgba(240, 168, 15, 1) 97%);
  } */
  .success-btns>:nth-child(2)>img{
    width: 43%;
    margin-left: 5px;
    margin-bottom: 2px;
  }
  
.success-coin-data-div{
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
  }
  
  .success-coin-data-div> :nth-child(1){
    padding: 0% 1.8%;
      width: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      aspect-ratio: auto 1 / 0.2;
      background-image: url('/src/images/dashed\ border.png');
      background-size: contain;  /* Makes the image cover the entire div */
      background-position: center; /* Center the image */
      background-repeat: no-repeat;
  }
  
  .success-coin-data-div> :nth-child(1)> :nth-child(2){
    display: flex;
    align-items: center;
    width: 22%;
  }
  
  .success-coin-data-div> :nth-child(1)> :nth-child(2)> span{
    color: white;
    font-weight: bold;
    font-size: 1.1vw;
  }
  
  .success-coin-data-div> :nth-child(1)> :nth-child(2)> img{
    width: 100%;
    margin-right: 7px;
  }
  
  .success-coin-data-div> :nth-child(3){
    padding: 0% 1.8%;
      width: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      aspect-ratio: auto 1 / 0.24;
      background-image: url('/src/images/dashed\ border.png');
      background-size: contain;  /* Makes the image cover the entire div */
      background-position: center; /* Center the image */
      background-repeat: no-repeat;
  }
  
  .success-coin-data-div> :nth-child(3)> :nth-child(2){
    display: flex;
    align-items: center;
    justify-content: end;
      width: 22%;
  }
  
  .success-coin-data-div> :nth-child(3)> :nth-child(2)> span{
    color: white;
    font-weight: bold;
    font-size: 1.1vw;
  }
  
  .success-coin-data-div> :nth-child(3)> :nth-child(2)> img{
    width: 100%;
    margin-right: 7px;
  }
  .success-you-send-amount> :nth-child(1){
    color: #918F8C;
    font-size: 0.9vw;
  }
  
  .success-you-send-amount> :nth-child(2){
    color: white;
    font-size: 1.3vw;
    font-weight: bold;
  }
  
.toicon{
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
  }
  .toicon_mobile{
    display: none;
  }
  img.toicon_desktop {
    width: 40px;
  }
.success-you-get-amount> :nth-child(1){
  color: #918F8C;
  font-size: 0.9vw;
}

.success-you-get-amount> :nth-child(2){
  color: white;
  font-size: 1.3vw;
  font-weight: bold;
}
.success-completion{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  .success-completion> :nth-child(1)>img{
    width: 20px;
    margin-right: 5px;
  }
  
  .success-completion> :nth-child(1){
    margin-right: 40px;
  }
  
  .success-completion>:nth-child(1)>span{
    color: #f4a70c;
    font-size: 12px;
  }
  
  .success-completion> :nth-child(2)>img{
    width: 20px;
    margin-right: 5px;
  }
  
  .success-completion>:nth-child(2)>:nth-child(2){
    color: #f4a70c;
    font-size: 12px;
    margin-right: 5px;
  }
  .success-completion>:nth-child(2)>:nth-child(3){
    color: #f4a70c;
    font-size: 12px;
  }
  .deposit-address-width{
    width: 350px;
    overflow: hidden;
  }
  .left-round-div{
    margin-top: 4%;
  }
  .recipient-address-width{
    width:400px;
    overflow: hidden;
  }
  .txhash-width{
    width: 500px;
    overflow: hidden;
  }
  #thumb-cursor {
    cursor: pointer !important; 
  }
  .floating-box-progress{
    width:100%; 
    margin:0px 20px;
    margin-right: 52.7%;
  }
  .trx-type-submit>:nth-child(1){
    width: 28px;
    margin-right: 12px;
  }
  
  .trx-type-submit>:nth-child(2){
  font-weight: bold;
  font-size: 18px;
  }
  .recipient-input-how-to> :nth-child(1){
    font-size: 25px;
  }

  .recipient-input-how-to> :nth-child(3){
    display: inline;
    font-size: 16px;
  }

  .recipient-input-how-to> :nth-child(2){
    font-size: 16px;
  }

  .recipient-input-how-to{
    padding: 0% 4%;
  }
  .three-and-four-step>:nth-child(2){
    margin-left: 50px;
  }

  .three-and-four-step>:nth-child(2)>:nth-child(1){
    display: block;
  }

  .three-and-four-step>:nth-child(2)>:nth-child(2){
    display: none;
    height: 150px;
  }