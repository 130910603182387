.pull{
    display: flex;
    align-items: center;
    padding: 1rem 0 1rem 1rem;
    text-align: right;
    user-select: none;
    cursor: pointer;
    background: #276c8e;
    color: rgb(231, 231, 231);
    h1, p{
      padding: 0;
      margin: 0;
    }
  }

  .sidebar {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    /* transform: translateX(-100%); */
    transition: transform 0.4s;
    background: white;
    color: rgb(44, 44, 44);
}

.sidebar--inner {
    margin: 0;
    padding: 0;
}

.sidebar--inner-item {
    list-style-type: none;
    padding: 0.6rem 1.2rem;
    margin: 0;
    background: rgb(229,228,226);
    background: linear-gradient(90deg, rgba(229,228,226,1) 26%, rgba(255,255,255,1) 96%);
    margin: 0.4rem 0rem;
    border-radius: 30px;
}

.sidebar--inner-item:hover {
    background: #d6d6d6;
    cursor: pointer;
}

.sidebar--inner-item__link {
    color:rgb(96 90 90);
}

.sidebar{
    z-index: 100;
    position: absolute;
    right: 0px;
    top: 0px;

}

.sidebar_container{
    padding: 20px 20px;
}
.sidebar_container>:nth-child(1){
    width: 140px;
}

.sidebar_container>:nth-child(2){
    border: solid black 1px;
    border-radius: 7px;
    margin: 10px 0px;
    height: 43px !important;
}

.sidebar--inner-item>:nth-child(1){
    width: 18px;
    margin-right: 10px;
}

.sidebarbtn{
    width: 100%;
    height: 43px;
    border-radius: 10px;
    background-color: rgb(240, 151, 13);
    color: black;
    font-family: basementgrotesque;
}

.sidemenu_close_btn{
    padding: 10px 15px;
    font-size: 12px;
}

.sidemenu_divider_one{
    height: 1px;
    margin: 20px 0px;
    background-color: #d1c5c5;
}

.sidemenu_divider_two{
    height: 1px;
    margin: 20px 0px;
    background-color: #d1c5c5;
}

.card-header-sidemenu{
    padding: 0px;
    background-color: transparent;
    border-bottom: none;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.shevron-sidemenu{
    color: rgb(81 78 78);
}

.btn-link:focus{
    box-shadow:unset;
    text-decoration: none;
}

.card-sidemenu{
    border: none;
}

.flags-selection-sidemenu img{
    width: 35px;
}

.flags-selection-sidemenu {
    padding: 10px 12px;
}

.flag-item-sidemenu{
    padding: 0.6rem 1.2rem;
    background: rgb(229,228,226);
    background: linear-gradient(90deg, rgba(229,228,226,1) 26%, rgba(255,255,255,1) 96%);
    margin: 0.4rem 0rem;
    border-radius: 30px;
}

@media screen and (max-width: 769px) {
    .flag{
      display: none;
    }

    .mobile-menu-div{
        display: block !important;
    }
}

@media screen and (max-width: 430px) {


}
