body{
    overflow-x: hidden;
}

/* Media query for small screnn 1024 screen*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .submit-data {
        font-size: 16px;
    }
    .rounded-div {
        color: white;
        border: 1px solid white;
        border-radius: 30px;
        padding: 12px 18px;
        margin-top: 13px;
        text-align: center;
        font-size: 13px;
    }
    .copy-icon {
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }
    .trx-type-submit {
        display: flex;
    }
    .partner-image {
        width: 70%;
        height: auto;
    }
    .submit-li {
        color: #928F8C;
        font-size: 12px;
        margin: 10px 0px;
    }
    .col-5.submit_uls {
        padding-right: 20px !important;
    }
    .QR_code {
        width: 223px !important;
    }
    svg.QR_inner {
        padding: 0px 15px 1px 0px;
    }
    .deposit_adres {
        margin: 2px auto !important;
        text-align: center;
        width: 25%;
    }
}
/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){

    .progress-div-awaiting {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: auto;
        padding: 13% 11% 0% 11%;
    }
    .progress-div-awating-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }
    .progress-div-awating-status> :nth-child(1) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: 40px;
    }
    .progress-div-awating-status> :nth-child(2) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: -57px;
    }
    .back_btn {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0% 0% -6.5% 19%;
    }
    .progress-div-awating-status> :nth-child(3) {
        color: white;
        font-weight: 700;
        margin-right: 54px;
    }
    .enter-address-text-div {
        text-align: center;
        margin-top: 95px;
        margin-bottom: 60px;
    }
    .enter-address-text-div> :nth-child(3) {
        padding-top: 30px;
    }
    .QRadress_desktop{
        display: none;
    }
    .QRadress_mobile{
        display: block !important;
        width: 100%;
      }
      .col-2.QR_col {
        display: flex;
        justify-content: start !important;
    }
    .QR_code {
        width: 260px !important;
        height: 260px !important;
    }
    .QR_inner {
        height: auto;
        width: 100% !important;
    }
      .submit-deposit-address-width {
        width: 550px !important;
        overflow: hidden;
        text-align: center;
    }
    .rounded-div-align-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span.react-draggable {
        font-size: 22px;
    }
    span.react-draggable {
        margin-left: -41px;
        font-size: 23px;
        color: #A3A09E;
    }
    
    .deposit_adres {
        margin: 0px auto;
        text-align: center;
        width: 100%;
        height: 122px;
        margin-top: 10%;
        padding-left: 45px;
    }
    .recipient-adress {
        padding-left: 45px;
        margin: 33px auto;
        text-align: center;
        width: 100%;
    }
    .recipient-address-submit-width {
        width: 550px !important;
        overflow: hidden !important;
    }
    .Remaining_time {
        width: 100%;
        text-align: center;
        margin-top: 85px;
        padding-left: 45px;
    }
    .submit-data {
        font-size: 20px;
        text-align: center;
    }
    .submit-partner-logo {
        width: 62% !important;
        margin: 0px auto;
    }
    .exchange_partner {
        width: 100%;
        margin: 45px auto;
        text-align: center;
        padding-left: 45px;
    }
    .exchange_img {
        max-width: 45%;
        height: auto;
    }
    .exchange_heading {
        font-size: 28px;
    }
    .order_track {
        width: 100%;
        margin: 0px auto;
        text-align: center;
        padding-left: 45px;
    }
    .exchange_type {
        width: 100%;
        margin: 45px auto;
        text-align: center;
        padding-left: 45px;
    }
    .submit_uls {
        padding: 15px 0px 15px 25px;
    }
    .recipient-input-how-to {
        text-align: center;
        margin-top: 110px;
    }
    .three-and-four-step {
        width: 700px;
        display: flex;
        align-items: start !important;
        margin: auto;
        margin-top: 100px;
        flex-direction: column;
        margin-bottom: 200px;
        gap: 70px;
        padding-left: 80px;
    }
    .horizontal_line{
        display: none;
    }
    img.vertical_line {
        display: block !important;
    }
    .three-and-four-step>:nth-child(2) {
        width: 250px;
        margin-left: 55px;
        margin-top: -29px;
    }
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
    .deposit-address-desktop{
        display: none ;
    }
    .progress-div-awating-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }
    .progress-div-awaiting {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: auto;
        padding: 13% 11% 0% 11%;
    }
    .progress-div-awating-status> :nth-child(1) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: 18px;
        font-size: 12px;
    }
    .progress-div-awating-status> :nth-child(2) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: -43px;
        font-size: 12px;
    }
    .back_btn {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0% 0% -11.5% 6%;
    }
    .progress-div-awating-status> :nth-child(3) {
        color: white;
        font-weight: 700;
        margin-right: 40px;
        font-size: 12px;
    }
    .enter-address-text-div {
        text-align: center;
        margin-top: 95px;
        margin-bottom: 60px;
    }
    .enter-address-text-div> :nth-child(3) {
        padding: 30px 20px 0px 20px;
    }
    .QRadress_desktop{
        display: none;
    }
    .QRadress_mobile{
        display: block !important;
        width: 100%;
      }
      .QR_col {
        width: 100%;
        margin: 0px auto;
        padding-left: 50px;
    }
      .col-2.QR_col {
        display: flex;
        justify-content: start !important;
    }
    .QR_code {
        width: 260px !important;
        height: 260px !important;
    }
    .QR_inner {
        height: auto;
        width: 100% !important;
    }
      .submit-deposit-address-width {
        width: 100% !important;
        overflow: hidden;
        text-align: center;
    }
    span.react-draggable {
        font-size: 22px;
    }
    span.react-draggable {
        font-size: 12px;
        color: #A3A09E;
    }
    .copy-icon {
        width: 20px;
        height: 20px;
        margin-left: -65px;
    }
    .deposit_adres {
        margin: 0px auto;
        text-align: center;
        width: 100%;
        margin-top: 10%;
        padding-left: 45px;
    }
    .recipient-adress {
        margin: 33px auto;
        text-align: center;
        width: 100%;
        padding-left: 45px;
    }
    .recipient-address-submit-width {
        width: 100%;
        overflow: hidden;
    }
    .Remaining_time {
        width: 100%;
        text-align: center;
        padding-left: 45px;
        margin-top: 80px;
    }
    .submit-data {
        font-size: 20px;
        text-align: center;
    }
    .submit-partner-logo {
        width: 100% !important;
    }
    .exchange_partner {
        width: 100%;
        margin: 45px auto;
        text-align: center;
        padding-left: 45px;
    }
    .exchange_img {
        max-width: 45%;
        height: auto;
    }
    .exchange_heading {
        font-size: 16px;
    }
    .order_track {
        width: 100%;
        margin: 0px auto;
        text-align: center;
        padding-left: 45px;
    }
    .exchange_type {
        width: 100%;
        text-align: center;
        padding: 30px 15px 10px 45px;
    }
    img#thumb-cursor {
        width: 20px;
        height: 20px;
        margin-left: 15px;
    }
    .submit_uls {
        padding: 15px 25px 15px 24px;
    }
    .recipient-input-how-to {
        text-align: center;
        margin-top: 105px !important;
    }
    .recipient-input-how-to> :nth-child(1) {
        font-size: 24px !important;
    }
    .recipient-input-how-to> :nth-child(2) {
        font-size: 16px;
    }
    .recipient-input-how-to> :nth-child(3) {
        font-size: 16px;
    }
    .three-and-four-step {
        align-items: start !important;
        flex-direction: column;
        gap: 70px;
        padding-left: 35px; 
    }
    .horizontal_line{
        display: none;
    }
    img.vertical_line {
        display: block !important;
    }
    .three-and-four-step>:nth-child(2) {
        width: 250px;
        margin-left: 55px;
        margin-top: -29px;
    }
    .three-and-four-step {
        margin-bottom: 113px !important;
    }
}
@media only screen and (max-width: 375px){
    .QRadress_mobile {
        display: block !important;
        width: 100%;
    }
    .progress-div-awating-status> :nth-child(1) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: 18px;
        font-size: 12px;
    }
    .progress-div-awating-status> :nth-child(2) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: -17px;
        font-size: 12px;
    }
    .progress-div-awating-status> :nth-child(3) {
        color: white;
        font-weight: 700;
        margin-right: 15px;
        font-size: 12px;
    }
    .back_btn {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0% 0% -13.5% 4%;
    }
    .enter-address-text-div> :nth-child(3) {
        padding: 30px 20px 0px 20px;
    }
}

@media only screen and (max-width: 430px){

    .progress-div-awaiting{
        width: 87%;
    }

    .progress-div-awating-status> :nth-child(1) {
        font-size: 10px;
        margin-left: 10%;
    }

    .progress-div-awating-status> :nth-child(2) {
        margin-left: -4%;
        font-size: 10px;
    }

    .progress-div-awating-status> :nth-child(3) {
        margin-right: 15%;
        font-size: 10px;
    }

}

@media only screen and (max-width: 370px){

    .progress-div-awating-status> :nth-child(1) {
        margin-left: 7%;
    }

    .progress-div-awating-status> :nth-child(2) {
        margin-left: -4%;
    }

    .progress-div-awating-status> :nth-child(3) {
        margin-right: 14%;
    }

}

@media only screen and (max-width: 320px){
    .QRadress_mobile {
        display: block !important;
        width: 100%;
    }
    .Remaining_time {
        width: 100%;
        text-align: center;
        margin-top: 95px;
    }
    .back_btn {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0% 0% -18.5% 1%;
    }
    .progress-div-awating-status> :nth-child(1) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: 13px;
        font-size: 12px;
    }
    .progress-div-awating-status> :nth-child(2) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: -8px;
        font-size: 12px;
    }
    .progress-div-awating-status> :nth-child(3) {
        color: white;
        font-weight: 700;
        margin-right: 15px;
        font-size: 12px;
    }
}

